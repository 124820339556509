.sidebar {
  padding-top: 20px;
  width: 250px;
  height: 100%;
  z-index: 1;
  position: fixed;
  left: 0;
  background-color: #1f2a40;
  color: #fff;
  transition: ease-in-out 0.5s;
}

.sidebar.isCollapsed {
  width: 80px !important;
}

.sidebar.isCollapsed .sidebar-title .sidebar-btn button {
  margin: 0 !important;
}

.sidebar .sidebar-title {
  display: flex;
  justify-content: center;
} 

.sidebar .sidebar-title .sidebar-titleText a {
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: #FFF;
}

.sidebar .sidebar-title .sidebar-btn button {
  margin-left: 20px;
  border-radius: 50px;
  background-color: transparent;
  border: none;
  color: white;
}

.sidebar .sidebar-title .sidebar-btn button:hover {
  background-color: rgba(243, 243, 243, 0.3);
}

.sidebar .sidebar-profile {
  font-family: Calibri;
  text-align: center;
}

.sidebar .sidebar-profile .sidebar-picture {
  text-align: center;
}

.sidebar .sidebar-profile .sidebar-picture img {
  width: 100%;
}

.sidebar .sidebar-profile .sidebar-name {
  font-size: 30px;
}

.sidebar .sidebar-profile .sidebar-profession {
  color: #4cceac;
}

.sidebar ul {
  list-style: none;
  padding: 20px;
}

.sidebar ul li {
  padding: 10px;
  display: flex;
}

.sidebar ul li a,
.sidebar ul li>span {
  width: 100%;
}

.sidebar ul li span .chevron {
  float: right;
}

.sidebar ul li a:hover {
  color: #868dfb;
}

.sidebar ul li a.active{
  color: #868dfb !important;
}

.sidebar ul li .sidebar-icon {
  padding-right: 20px;
}

.sidebar ul li a:hover .sidebar-icon i {
  animation: shake 0.5s !important;
}

.sidebar ul li a {
  color: #fff;
  text-decoration: none;
  display: block;
}

.sidebar ul li span .submenu {
  display: none;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.sidebar ul li span .submenu.open {
  display: inline;
  max-height: 500px; /* Adjust based on content height */
}

.sidebar ul li span .submenu a {
  padding: 10px 15px;
  display: block;
  color: #fff;
  text-decoration: none;
}

.sidebar ul li span .submenu a:hover {
  color: #868dfb;
}

.sidebar ul li span.withSub > div.tooltip-menu {
  display: none; /* Make sure tooltip-menu is positioned relative to its parent */
}

.sidebar.isCollapsed ul li span.withSub {
  position: relative; /* Make sure tooltip-menu is positioned relative to its parent */
}

.sidebar.isCollapsed ul li span.withSub > .sidebar-icon:hover > div.tooltip-menu {
  display: block; /* Show the tooltip-menu on hover */
  background-color: #5c677d;
  width: 150px;
  border-radius: 10px;
  position: absolute;
  left: 45px;
  padding: 10px;
  top: -10%;
}

.sidebar.isCollapsed ul li span.withSub > .sidebar-icon > div.tooltip-menu {
  display: none; /* Hide the tooltip-menu by default */
  position: absolute;
  left: 45px;
  background-color: #5c677d;
  width: 150px;
  border-radius: 10px;
  padding: 10px;
  top: -10%;
}

.sidebar.isCollapsed ul li span.withSub > div.tooltip-menu a {
  color: #fff; /* Ensure text color in tooltip-menu is readable */
}


.sidebar ul li span.withSub > div.tooltip-menu::before {
  content: ''; /* No text, just a visual element */
  position: absolute;
  top: -5%;
  left: -10px; /* Adjust based on the size of the tooltip and desired offset */
  width: 0;
  height: 0;
  border-right: 10px solid #5c677d; /* Color should match the background of tooltip-menu */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transform: translateY(50%) translateX(10%); /* Center the arrow vertically */
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  padding: 10px;
  font-size: 10px;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}