:root {
  --margin: 250px;
  --top: 60px;
  --background: #f3f3f3;
}

* {
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  background-color: var(--background);
  color: #000;
  /* -webkit-font-smoothing: antialiased; */
  font-family: 'Inter', sans-serif;
}

.layout {
  display: flex; /* Use flexbox for layout */
}

.main-content {
  flex: 1; /* Take up remaining space */
  padding: 20px;
  margin-left: var(--margin);
  margin-top: var(--top);
  transition: ease-in-out 0.5s;
}

.topbar {
  position: fixed;
  margin-left: var(--margin);
  height: var(--top);
  background-color: var(--background);
  width: calc(100% - var(--margin));
}

.topbar span,
.topbar span h3{
  line-height: var(--top);
}

.topbar span h3{
  float: right;
  padding-right: 20px;
  cursor: pointer;
}

.main-content.isCollapsed {
  flex: 1;
  margin-left: 80px;
}

.main-content .title {
  display: flex;
}

.main-content .title-text {
  padding-left: 10px;
}

.content-holder {
  background-color: #1f2a40;
  border-radius: 5px;
  padding: 20px;
}


@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-VariableFont_slnt\,wght.ttf');
  font-weight: normal;
  font-style: normal;
}