
  
  .dashboard .title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .dashboard .card-body {
    display: flex;
  }

  .dashboard .card-details {
    flex: 0.8;
  }

  .dashboard .card-icon {
    flex: 0.2;
    font-size: 30px;
  }