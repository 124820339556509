
@media only screen and (max-width: 600px) {
    .inventory .MyTable {
        max-width: 380px;
        overflow-x: auto;
    }
  }


@media only screen and (max-width: 499px) {
    .inventory .MyTable {
        max-width: 300px;
        overflow-x: auto;
    }
  }

.el-input {
  border-radius: 5px;
  border: none;
  padding: 5px;
}

.el-suffix {
  color: #000;
  right: 25px;
  transition: ease-in-out 0.3s;
  position: relative;
}

.el-dropdown {
  margin-top: 10px;
  background-color: #FFF;
  width: fit-content;
  min-width: 190px;
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.el-dropdown::before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  content: '';
  display: block;
  z-index: 1;
}

.table>:not(caption)>*>* {
  background-color: transparent;
  color: #ebeef5;
}